var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AView',{staticClass:"proposal-assistant-container",attrs:{"value":_vm.value,"actions":_vm.actions},on:{"onActivate":(e) => _vm.$emit('onActivate', e),"onMaximize":(e) => _vm.$emit('onMaximize', e),"onClose":(e) => _vm.$emit('onClose', e),"onExpand":(e) => _vm.$emit('onExpand', e),"onCollapse":(e) => _vm.$emit('onCollapse', e),"onPresentationGeneration":_vm.onExport},scopedSlots:_vm._u([{key:"content",fn:function({}){return [_c('v-row',{staticClass:"scroll-row d-flex flex-grow-1"},[_c('v-col',{staticClass:"pa-0 ma-0 d-flex flex-column flex-grow-1",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pa-2 slides-col",attrs:{"cols":"12"}},[_c('FormSection',{staticClass:"slides-title",attrs:{"center":"","label":'Slides',"actions":_vm.actions,"editable":!!_vm.actions.length,"payload":{
                from: _vm.value,
              }}}),(_vm.slides && _vm.slides.length)?_c('draggable',_vm._b({staticClass:"list-group",attrs:{"tag":"ul"},on:{"start":function($event){_vm.isDragging = true},"end":function($event){_vm.isDragging = false}},model:{value:(_vm.slides),callback:function ($$v) {_vm.slides=$$v},expression:"slides"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.isDragging ? 'flip-list' : null}},_vm._l((_vm.slides),function(slide,i){return _c('li',{key:`${slide.id}_${slide.type}`,staticClass:"list-group-item"},[_c('SlideItem',{staticClass:"my-2",attrs:{"index":i},on:{"onSlideRemove":function($event){return _vm.onSlideRemove(slide)}},model:{value:(_vm.slides[i]),callback:function ($$v) {_vm.$set(_vm.slides, i, $$v)},expression:"slides[i]"}})],1)}),0)],1):_c('v-alert',{attrs:{"prominent":"","text":"","type":"info"}},[_vm._v(" Nothing to display. Please, attach items by click on the \"+\" button to start. ")])],1)],1)],1),_c('PageNavigationRightVue',{attrs:{"buttons":_vm.navigationButtons,"hideOnClickOutside":false},on:{"onHideEditor":function($event){_vm.isNavigationOpen = true},"onShowEditor":function($event){_vm.isNavigationOpen = false},"onConfig":() => {
            _vm.isNavigationOpen = true;
            _vm.tab = 1;
          },"onAttachments":() => {
            _vm.isNavigationOpen = true;
            _vm.tab = 0;
          }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('PresentMasterForm',{model:{value:(_vm.recommended),callback:function ($$v) {_vm.recommended=$$v},expression:"recommended"}}),_c('v-btn',{attrs:{"disabled":!_vm.moveBtnAvailable,"color":"primary"},on:{"click":_vm.attachToSlides}},[_vm._v(" Attach To Presentation ")])],1),_c('v-tab-item',[_c('PresentMasterExportForm',{model:{value:(_vm.exportConfig),callback:function ($$v) {_vm.exportConfig=$$v},expression:"exportConfig"}}),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.onExport}},[_vm._v(" Export ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.isNavigationOpen),callback:function ($$v) {_vm.isNavigationOpen=$$v},expression:"isNavigationOpen"}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }