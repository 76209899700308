<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    class="proposal-assistant-container"
    @onPresentationGeneration="onExport"
  >
    <template v-slot:content="{}">
      <v-row class="scroll-row d-flex flex-grow-1">
        <v-col cols="12" class="pa-0 ma-0 d-flex flex-column flex-grow-1">
          <!-- Main Form -->

          <v-row>
            <v-col cols="12" class="pa-2 slides-col">
              <FormSection
                center
                :label="'Slides'"
                class="slides-title"
                :actions="actions"
                :editable="!!actions.length"
                :payload="{
                  from: value,
                }"
              ></FormSection>
              <draggable
                v-if="slides && slides.length"
                class="list-group"
                tag="ul"
                v-model="slides"
                v-bind="dragOptions"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  :name="!isDragging ? 'flip-list' : null"
                >
                  <li
                    class="list-group-item"
                    v-for="(slide, i) in slides"
                    :key="`${slide.id}_${slide.type}`"
                  >
                    <SlideItem
                      :index="i"
                      v-model="slides[i]"
                      class="my-2"
                      @onSlideRemove="onSlideRemove(slide)"
                    ></SlideItem></li
                ></transition-group>
              </draggable>
              <v-alert v-else prominent text type="info">
                Nothing to display. Please, attach items by click on the "+"
                button to start.
              </v-alert>
            </v-col>
          </v-row>
        </v-col>

        <PageNavigationRightVue
          v-model="isNavigationOpen"
          :buttons="navigationButtons"
          :hideOnClickOutside="false"
          @onHideEditor="isNavigationOpen = true"
          @onShowEditor="isNavigationOpen = false"
          @onConfig="
            () => {
              isNavigationOpen = true;
              tab = 1;
            }
          "
          @onAttachments="
            () => {
              isNavigationOpen = true;
              tab = 0;
            }
          "
        >
          <template v-slot:content>
            <v-tabs-items v-model="tab" class="transparent">
              <v-tab-item>
                <PresentMasterForm v-model="recommended"></PresentMasterForm>
                <v-btn
                  :disabled="!moveBtnAvailable"
                  color="primary"
                  @click="attachToSlides"
                >
                  Attach To Presentation
                </v-btn>
              </v-tab-item>
              <v-tab-item>
                <PresentMasterExportForm
                  v-model="exportConfig"
                ></PresentMasterExportForm>
                <v-btn :loading="loading" color="primary" @click="onExport">
                  Export
                </v-btn>
              </v-tab-item>
            </v-tabs-items>
          </template>
        </PageNavigationRightVue>
      </v-row>
    </template>
  </AView>
</template>
          
          
          
<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import { PRESENT_MASTER_BUTTONS } from "../constants/actions.gh";
import { PermissionsHelper } from "../../../../../components/helpers/permissions.helper";
import PresentMasterForm from "../components/forms/PresentMasterForm.vue";
import PresentMasterExportForm from "../components/forms/PresentMasterExportForm.vue";
import SlideItem from "../components/SlideItem.vue";
// import moment from "moment";
import PageNavigationRightVue from "../../../../../components/wad/molecules/layout/PageNavigationRight.vue";

export default {
  name: "ProposalAssistant",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    draggable,
    AView,
    FormSection,
    PresentMasterForm,
    SlideItem,
    PageNavigationRightVue,
    PresentMasterExportForm,
  },
  computed: {
    ...mapState("PresentMasterStore", ["loading"]),
    actions: function () {
      return this.slides && this.slides.length
        ? PermissionsHelper.getActions(
            [PRESENT_MASTER_BUTTONS.SAVE],
            this.value.permissions
          )
        : [];
    },
  },
  async mounted() {
    if (this.leadId) {
      this.recommended = await this.$store.dispatch(
        "LeadStore/getLeadRecommended",
        {
          id: this.leadId,
          refresh: true,
        }
      );
      this.exportConfig.lead = await this.$store.dispatch("LeadStore/GetLead", {
        id: this.leadId,
        refresh: true,
      });
    }

    if (this.proposalId) {
      const proposal = await this.$store.dispatch(
        "ProposalsStore/GetProposal",
        {
          id: this.proposalId,
        }
      );
      console.log("Proposal: ", proposal);
      this.recommended.proposals = [proposal];
    }
    // this.watcher = this.$watch(
    //   "$refs.proposalAssistantContainer",
    //   (newVal) => {
    //     console.log("HTMMM????????", newVal);
    //   },
    //   {
    //     deep: true,
    //   }
    // );
  },
  beforeDestroy() {
    // if (this.watcher) this.watcher();
  },
  data() {
    const leadId =
      this.value.relation &&
      this.value.relation.params &&
      this.value.relation.params.leadId
        ? this.value.relation.params.leadId
        : undefined;

    const proposalId =
      this.value.relation &&
      this.value.relation.params &&
      this.value.relation.params.proposalId
        ? this.value.relation.params.proposalId
        : undefined;

    return {
      leadId,
      proposalId,
      recommended: {
        services: [],
      },
      exportConfig: {
        lead: undefined,
        folder: {
          id: 0,
          name: "Home",
        },
      },
      slides: [],
      watcher: undefined,
      isDragging: false,
      delayedDragging: false,
      tab: 0,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },

      isNavigationOpen: false,
      navigationButtons: [
        {
          name: "Expand Config",
          action: "onExpand",
          openIcon: "mdi-chevron-right",
          closeIcon: "mdi-plus",
        },
        {
          name: "Attachments",
          action: "onAttachments",
          openIcon: "mdi-paperclip-plus",
          closeIcon: "mdi-paperclip-plus",
        },
        {
          name: "Export Config",
          action: "onConfig",
          openIcon: "mdi-cog-outline",
          closeIcon: "mdi-cog-outline",
        },
      ],
    };
  },
  methods: {
    moveBtnAvailable() {
      return (
        (this.recommended.domains && this.recommended.domains.length) ||
        (this.recommended.services && this.recommended.services.length) ||
        (this.recommended.caseStudies && this.recommended.caseStudies.length)
      );
    },
    onExport() {
      this.$store.dispatch("PresentMasterStore/initGeneration", {
        from: this.value,
        generationRequest: {
          name: this.exportConfig.name,
          folder_id: this.exportConfig.folder
            ? this.exportConfig.folder.id
            : undefined,
          lead_id: this.exportConfig.lead
            ? this.exportConfig.lead.id
            : undefined,
          format: this.exportConfig.format, //'pdf' | 'pptx'
          items: this.slides.map((slide, i) => ({
            id: slide.id,
            type: slide.type,
            order: i,
          })),
        },
      });
    },

    attachToSlides() {
      const {
        services,
        caseStudies,
        domains,
        slides,
        proposals,
        experienceNotes,
      } = this.recommended;

      if (services && services.length)
        services.forEach((el) => {
          this.slides.push({
            type: "services",
            id: el.id,
            item: el,
          });
        });
      if (caseStudies && caseStudies.length)
        caseStudies.forEach((el) => {
          this.slides.push({
            type: "case_studies",
            id: el.id,
            item: el,
          });
        });
      if (domains && domains.length)
        domains.forEach((el) => {
          this.slides.push({
            type: "business_domains",
            id: el.id,
            item: el,
          });
        });

      if (slides && slides.length)
        slides.forEach((el) => {
          this.slides.push({
            type: "slides",
            id: el.id,
            item: el,
          });
        });

      if (proposals && proposals.length)
        proposals.forEach((el) => {
          this.slides.push({
            type: "proposals",
            id: el.id,
            item: el,
          });
        });

      if (experienceNotes && experienceNotes.length)
        experienceNotes.forEach((el) => {
          this.slides.push({
            type: "experience_notes",
            id: el.id,
            item: el,
          });
        });

      this.recommended.domains = [];
      this.recommended.caseStudies = [];
      this.recommended.services = [];
      this.recommended.slides = [];
      this.recommended.proposals = [];
      this.recommended.experienceNotes = [];
    },
    onSlideRemove(slide) {
      const index = this.slides.findIndex(sl=>sl.type===slide.type && sl.id===slide.id);
      this.slides.splice(index, 1);
    },
    // updateSlides(recommended) {
    //   this.slides = [
    //     ...(recommended.caseStudies && recommended.caseStudies.length
    //       ? recommended.caseStudies.map((el) => ({
    //           type: "case_studies",
    //           id: el.id,
    //           item: el,
    //         }))
    //       : []),
    //     ...(recommended.services && recommended.services.length
    //       ? recommended.services.map((el) => ({
    //           type: "services",
    //           id: el.id,
    //           item: el,
    //         }))
    //       : []),
    //     ...(recommended.domains && recommended.domains.length
    //       ? recommended.domains.map((el) => ({
    //           type: "business_domains",
    //           id: el.id,
    //           item: el,
    //         }))
    //       : []),
    //   ];
    // },
  },
};
</script>
          
          
          
  <style scoped lang="scss" >
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
  list-style: none;
  padding: 0px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.proposal-assistant-container {
  container-type: inline-size;
}

.scroll-row {
  height: 100%;
}
.slides-col {
  position: relative;

  .slides-title {
    margin-top: 60px;

    @container (min-width: 610px) {
      margin-top: 30px;
    }
  }

  .move-container {
    position: absolute;
    border-left: 1px solid;
    z-index: -1;
    cursor: pointer;
    z-index: 1;
    width: 100%;
    left: 0;
    border-bottom: 1px solid;
    top: 30px;

    transition: all 0.1s;

    @container (min-width: 610px) {
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      // animation: move 0.5s infinite;
    }

    .move-btn {
      position: absolute;
      z-index: 9;
      left: 50%;
      top: 50%;
      transform: rotate(90deg) translate(-50%, 50%);

      @container (min-width: 610px) {
        transform: translate(-50%, 50%) rotate(0deg);
        top: 50%;
        left: 0;

        // animation: move 0.5s infinite;
      }
    }
  }
}

@keyframes move {
  /* You could think of as "step 1" */
  0% {
    opacity: 0;
  }
  /* You could think of as "step 2" */
  100% {
    opacity: 1;
  }
}
</style>