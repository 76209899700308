<template>
  <v-form
    v-bind="$attrs"
    ref="form"
    v-model="valid"
    lazy-validation
    @submit="onSubmit"
  >
    <FormSection
      :tag="'h2'"
      right
      :label="'Attach To'"
      :editable="false"
    ></FormSection>
    <SelectorLeads
      dark
      autoload
      label="Connect To Lead"
      helpText="You can select a lead to attach this presentation for"
      return-object
      v-model="form.lead"
    ></SelectorLeads>

    <FormSection
      :tag="'h2'"
      right
      :label="'Export Properties'"
      :editable="false"
    ></FormSection>
    <HelpFormInputVue
      dark
      v-model="form.name"
      :rules="nameRules"
      label="Presentation Name"
      :helpText="'Provide a name for the new Presentation'"
      required
    ></HelpFormInputVue>

    <SelectorFolders
      dark
      autoload
      label="Export Folder"
      helpText="Pick a folder where you want to save presentation"
      return-object
      v-model="form.folder"
    ></SelectorFolders>
  </v-form>
</template>
      
      
  <script>
import SelectorFolders from "../../../../../../components/wad/atoms/common/autocompletes/SelectorFolders.vue";
import SelectorLeads from "../../../../../../components/wad/atoms/common/autocompletes/SelectorLeads.vue";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: true,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Folder Name is required"],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorFolders,
    FormSection,
    SelectorLeads,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onSubmit(e) {
      e.preventDefault();
      this.$emit("onSubmit");
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
};
</script>