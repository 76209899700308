<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :items="pSlides"
    item-value="id"
    item-text="name"
    clearable
    cache-items
    :search-input.sync="search"
    chips
    :loading="api.loading"
    :color="$attrs.dark || $attrs.dark === '' ? 'white' : 'accent'"
  >
    <template v-slot:append-outer>
      <v-tooltip v-bind="tooltipAttrs">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
            mdi-help
          </v-icon>
        </template>
        <span>{{ helpText }}</span>
      </v-tooltip>
    </template>

    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="
          (...props) => {
            data.select(...props);
            $emit('onItemClick', data.item);
          }
        "
      >
        <!-- close
          @click:close="remove(data.item)" -->

        <v-avatar tile left>
          <img
            class="slide-preview"
            referrerpolicy="no-referrer"
            :src="data.item.file.preview"
          />
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>

    <template v-slot:item="data">
      <v-list-item-avatar tile>
        <img
          class="slide-preview"
          referrerpolicy="no-referrer"
          :src="data.item.file.preview"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ data.item.file.name }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
  
  
  <script>
import { DocAssistantAPInstance } from "../../../../api";

export default {
  props: {
    value: {},
    items: {
      type: Array,
      default: () => [],
    },
    helpText: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 400,
    },
    tooltipAttrs: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
    autoload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      api: DocAssistantAPInstance.Slides,
      search: "",
      pTimeout: undefined,
      pSlides: this.items,

      // Autoload
      pagination: {
        page: 1,
        pageSize: 50,
      },
    };
  },
  mounted() {
    if (this.autoload) this.getList();
  },
  methods: {
    async getList(search) {
      const { pagination, items } = await this.api.list(this.pagination, {
        search: search ? search : this.search,
      });

      this.pagination = pagination;

      this.pSlides = items;
    },
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
  },
  watch: {
    search(newVal) {
      if (this.pTimeout) clearTimeout(this.pTimeout);

      this.pTimeout = setTimeout(() => {
        if (this.autoload) this.getList(newVal);
        this.$emit("onSearch", newVal);
      }, this.timeout);
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.v-input__control {
  div {
    background: transparent !important;
  }
}
.v-input__slot {
  background: transparent;
}

.slide-preview {
  widows: 100%;
}
</style>