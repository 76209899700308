<template>
  <v-card
    :loading="loading"
    class="slide-item bg-grey-gradient"
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
    @contextmenu.prevent.stop="(e) => $emit('contextmenu', e, value)"
  >
    <v-list-item>
      <div class="index-wrapper">
        <span class="slide-index"> #{{ index + 1 }} </span>
      </div>

      <v-list-item-avatar
        tile
        class="ml-2 mr-2 slide-preview"
        width="auto"
        height="80px"
      >
        <img
          v-if="value.type === 'slides'"
          class="slide-preview"
          referrerpolicy="no-referrer"
          :src="value.item.file.preview"
          :lazy-src="value.item.file.preview"
        />

        <img
          v-else-if="
            value.type === 'case_studies' &&
            value.item.publicMaterials &&
            value.item.publicMaterials[0]
          "
          class="slide-preview"
          referrerpolicy="no-referrer"
          :src="value.item.publicMaterials[0].url"
          :lazy-src="value.item.publicMaterials[0].url"
        />
        <v-tooltip bottom v-else-if="value.type === 'business_domains'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-domain</v-icon>
          </template>
          <span>Business Domain</span>
        </v-tooltip>

        <v-tooltip bottom v-else-if="value.type === 'proposals'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-chart-timeline</v-icon>
          </template>
          <span>Proposal</span>
        </v-tooltip>

        <v-tooltip bottom v-else-if="value.type === 'services'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-briefcase</v-icon>
          </template>
          <span>Service</span>
        </v-tooltip>

        <v-tooltip bottom v-else-if="value.type === 'experience_notes'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-lightbulb-outline</v-icon>
          </template>
          <span>Experience Record</span>
        </v-tooltip>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-if="value.type === 'services' && value.item">
          {{ value.item.code }}
          {{ value.item.name }}
        </v-list-item-title>

        <template v-else-if="value.type === 'proposals'">
          <v-list-item-title>
            {{ value.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ priceFormatter(value.item.estCost) }}
            {{ value.item.durationString }}
          </v-list-item-subtitle>
        </template>

        <v-list-item-title v-else>
          {{ value.item.name }}
        </v-list-item-title>

        <!-- <v-list-item-subtitle
          >{{ dateFormatter(value.created_at) }}
        </v-list-item-subtitle> -->
      </v-list-item-content>

      <v-list-item-action>
        <v-btn icon @click="$emit('onSlideRemove', value)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <!-- <div class="card-actions-bottom">
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
  
          <v-btn color="secondary" text @click.stop.prevent="onOpen">
            Open
          </v-btn>
        </v-card-actions>
      </div> -->
  </v-card>
</template>
    
  <script>
import { FormatterHelper } from "../../../../../components/helpers/formatter.helper";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    allowSelection: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    moveInProgress: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    inSelected: function () {
      return this.selected && this.selected.length
        ? this.selected.find(
            (el) => el.id === this.value.id && el.u_type === this.value.u_type
          )
        : false;
    },
  },
  data() {
    return {
      durationFormatter: FormatterHelper.timeDuration,
      cutFormatter: FormatterHelper.cutString,
      dateFormatter: FormatterHelper.formatDate,
      priceFormatter: FormatterHelper.price,

      loading: false,
      checked: false,
    };
  },
  components: {},

  methods: {
    onCheckBox() {
      this.$emit("onSelect", this.checked, this.value);
    },
    onOpen() {
      this.$emit("onOpen", this.value);
    },
  },
  watch: {
    selected: {
      handler() {
        this.checked = this.inSelected;
      },
      deep: true,
    },
  },
};
</script>
    
    
  <style lang="scss" scoped>
.slide-item {
  overflow: hidden !important;
  display: flex;

  .index-wrapper {
    overflow: hidden;
    width: 40px;
    .slide-index {
      font-size: 18px;
    }
  }

  .slide-preview {
    height: 80px;
    width: auto;
  }

  .global-blur {
    z-index: -2;
    position: absolute;
    transition: all 0.3s;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.7);
    &.move-in-progress {
      z-index: 9999;
      opacity: 1;
    }
  }

  .checkbox {
    width: 0px;
    transition: all 0.3s;
    &.expanded {
      width: 40px;
    }
  }

  .slide-title {
    text-transform: uppercase;
    display: inline-block;
    padding-right: 60px;
  }

  .slide-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .file-preview-wrapper {
    height: 100%;
    width: 100%;
  }

  .file-preview-img {
    width: 100%;
    height: 100%;
  }

  .theme-default {
    height: 100%;
    width: 100%;
  }

  .grid-block-wrapper .grid-block {
    min-width: 80px;
    width: 80px;
  }

  .slide-icon {
    min-width: 80px;
    min-height: 80px;
    width: 100px;
  }

  .selection-icon {
    max-width: 40px;
  }
}
</style>