<template>
  <v-form
    v-bind="$attrs"
    ref="form"
    v-model="valid"
    lazy-validation
    @submit="onSubmit"
  >
    <SelectorSlides
      dark
      autoload
      label="Slides"
      helpText="You can select a set of Slides to include into Presentation"
      return-object
      v-model="form.slides"
      multiple
    ></SelectorSlides>

    <SelectorProposals
      dark
      autoload
      label="Proposals"
      helpText="You can select a set of Proposals to include into Presentation"
      return-object
      v-model="form.proposals"
      multiple
    ></SelectorProposals>

    <SelectorServices
      dark
      autoload
      label="Services"
      helpText="You can select a set of Services to include into Presentation"
      return-object
      v-model="form.services"
      multiple
    ></SelectorServices>

    <SelectorCaseStudies
      dark
      autoload
      label="Case Studies"
      helpText="You can select a set of Case Studies to include into Presentation"
      return-object
      v-model="form.caseStudies"
      multiple
    ></SelectorCaseStudies>

    <SelectorExperienceNotes
    dark
      autoload
      label="Experience Records"
      helpText="You can select a set of Experience Records to include into Presentation"
      return-object
      v-model="form.experienceNotes"
      multiple
    ></SelectorExperienceNotes>

    <SelectorBusinessDomains
      dark
      :icon="''"
      autoload
      label="Business Domains"
      helpText="You can select a set of Business Domains to include into Presentation"
      return-object
      v-model="form.domains"
      multiple
    ></SelectorBusinessDomains>
  </v-form>
</template>
    
    
<script>
import SelectorBusinessDomains from "../../../../../../components/wad/atoms/common/autocompletes/SelectorBusinessDomains.vue";
import SelectorCaseStudies from "../../../../../../components/wad/atoms/common/autocompletes/SelectorCaseStudies.vue";
import SelectorExperienceNotes from '../../../../../../components/wad/atoms/common/autocompletes/SelectorExperienceNotes.vue';
import SelectorProposals from "../../../../../../components/wad/atoms/common/autocompletes/SelectorProposals.vue";
import SelectorServices from "../../../../../../components/wad/atoms/common/autocompletes/SelectorServices.vue";
import SelectorSlides from "../../../../../../components/wad/atoms/common/autocompletes/SelectorSlides.vue";
// import HelpFormInputVue from "../../../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";


export default {
  props: {
    value: {
      default: () => ({}),
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    console.log("This value: ", this.value);

    return {
      valid: true,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Folder Name is required"],
    };
  },
  components: {
    // HelpFormInputVue,
    SelectorServices,
    SelectorCaseStudies,
    SelectorBusinessDomains,
    SelectorSlides,
    SelectorProposals,
    SelectorExperienceNotes
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onSubmit(e) {
      e.preventDefault();
      this.$emit("onSubmit");
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
};
</script>